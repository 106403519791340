<template lang="html">
  <header id="header">
    <div class="header-content">
      <section class="top-section">
        <div class="container med-container">
          <div class="row">
            <!-- <div class="col-lg col-form">
              <b-form @submit="onSubmit">
                <b-form-input
                  v-model="form.keywords"
                  type="text"
                  placeholder="SEARCH IN OUR KNOWLEDGE CENTER..."
                  size="sm"
                  required
                ></b-form-input>
                <b-button type="submit" size="sm"><i class="fas fa-search"></i></b-button>
              </b-form>
            </div> -->

            <div class="col-lg col-buttons">
              <a href="#">ATENCIÓN AL CLIENTE</a>
            </div>

            <!-- <div class="col-lg col-social-networks">
              <a class="btn-network" href="#"><i class="fab fa-google-play"></i></a>
              <a class="btn-network" href="#"><i class="fab fa-apple"></i></a>
            </div> -->
          </div>
        </div>
      </section>

      <section class="menu-section">
        <b-navbar toggleable="lg" type="dark" variant="info">
          <div class="container med-container">
            <b-navbar-brand to="/">
              <img src="public/images/logo.svg" alt="INVRENGAR">
            </b-navbar-brand>

            <b-navbar-toggle target="nav-collapse">
              <i class="far fa-bars"></i>
            </b-navbar-toggle>

            <b-collapse id="nav-collapse" is-nav>
              <b-navbar-nav class="ml-auto">
                <!-- <b-nav-item-dropdown right>
                  <template #button-content>
                    <span>EMPRESA</span>
                  </template>
                  <b-dropdown-item to="/about-us">Nosotros</b-dropdown-item>
                </b-nav-item-dropdown> -->

                <b-nav-item class="simple-item" to="/#s-companies" v-scroll-to="'#s-companies'">Empresas</b-nav-item>
                <!-- <b-nav-item class="simple-item" to="/comunicados">Comunicados</b-nav-item> -->

                <b-nav-item class="simple-item" to="/#s-corporativo" v-scroll-to="'#s-corporativo'" v-if="$route.name == 'homePage'">Corporativo</b-nav-item>
                <b-nav-item class="simple-item" to="/corporativo" v-else>Corporativo</b-nav-item>
                <b-nav-item class="simple-item" to="/contacto">Contacto</b-nav-item>

                <b-nav-item class="icon-item" to="/contacto">
                  <i class="fas fa-envelope icon"></i> <span class="d-lg-none">Contacto</span>
                </b-nav-item>

                <b-nav-item-dropdown class="icon-item" right>
                  <template #button-content>
                    <span>
                      <i class="fas fa-user-circle icon"></i> <span class="d-lg-none">Mi cuenta</span>
                  </span>
                  </template>
                  <b-dropdown-item to="/login">Iniciar sesión</b-dropdown-item>
                  <b-dropdown-item to="/registrarse">Registrarse</b-dropdown-item>
                </b-nav-item-dropdown>
              </b-navbar-nav>
            </b-collapse>
          </div>
        </b-navbar>
      </section>
    </div>
  </header>
</template>

<script>
export default {
  data() {
    return {
      form: {
        keywords: '',
      },
    }
  },

  methods: {
    onSubmit(event) {
      event.preventDefault();
    },
  }
}
</script>
