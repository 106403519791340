import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

//Componentes
//import Login from './components/admin/Login.vue';

const page="./components/page/";

const MyRouter = new VueRouter({
  	routes:[
      { path: '/', component: require(page+'home.vue').default, name: 'homePage' },
      { path: '/cv', component: require(page+'cv/index.vue').default, meta:{title:"CV"} },
      { path: '/corporativo', component: require(page+'corporative/index.vue').default, meta:{title:"Corporativo"} },
      { path: '/contacto', component: require(page+'contact/index.vue').default, meta:{title:"Contacto"} },

      { path: '/terminos-y-condiciones', component: require(page+'text-pages/terminos.vue').default, meta:{title:"Términos y condiciones"} },
      { path: '/aviso-de-privacidad', component: require(page+'text-pages/aviso.vue').default, meta:{title:"Aviso de privacidad"} },
      { path: '/garantias', component: require(page+'text-pages/garantias.vue').default, meta:{title:"Garantías"} },

      { path: '/invrengar', component: require(page+'companies/invrengar.vue').default },
      { path: '/brick-dream', component: require(page+'companies/brick-dream.vue').default, meta:{title:"Brick Dream"} },
      { path: '/piedrangular', component: require(page+'companies/piedrangular.vue').default, meta:{title:"Piedrangular"} },

      { path: '/comunicados', component: require(page+'blog/index.vue').default, meta:{title:"Comunicados Oficiales"} },
      { path: '/comunicados/:id', component: require(page+'blog/detail.vue').default, meta:{title:"Comunicados Oficiales"} },

      { path: '/login', component: require(page+'user-access/login.vue').default, meta:{title:"Iniciar sesión"}},
  		{ path: '/recuperar-contrasena', component: require(page+'user-access/reset-password.vue').default, meta:{title:"Recuperar contraseña"}},
  		{ path: '/registrarse', component: require(page+'user-access/register.vue').default, meta:{title:"Registrarse"}},

  		{ path: '/usuario', component: require(page+'usuario-cuenta/index.vue').default, meta:{ title: 'Mi datos' },
  			children: [
  			{
  				path: '/',
  				component: require(page+'usuario-cuenta/mis-datos.vue').default,
  				meta:{ title: 'Mis datos' }
  			},
  			{
  				path: 'contrasena',
  				component: require(page+'usuario-cuenta/contrasena.vue').default,
  				meta:{ title: 'Cambiar contraseña' }
  			},

  			// {
  			//   path: 'nueva-cotizacion',
  			//   component: require(page+'usuario-cuenta/nueva-cotizacion.vue').default,
  			//   meta:{ title: 'Mis cotizaciones' }
  			// },
  			// {
  			//   path: 'mis-cotizaciones',
  			//   component: require(page+'usuario-cuenta/mis-cotizaciones.vue').default,
  			//   meta:{ title: 'Mis facturas' }
  			// }
  	  	]
      },

	    // { path: '/checkout', component: require(page+'checkout.vue').default, meta:{title:"Checkout"}},
	  ]
});

MyRouter.beforeEach((to, from, next) => {
	window.scrollTo(0,0);
	if(window.app.__vue__ && window.app.__vue__.$refs.loadingBar){
		window.app.__vue__.$refs.loadingBar.start();
	}
	next();
});

MyRouter.afterEach((to, from) => {

	if(window.app.__vue__ && window.app.__vue__.$refs.loadingBar){
		setTimeout(()=>{
			window.app.__vue__.$refs.loadingBar.done();
		},500);
	}


});

//Titulos del website
import VueDocumentTitlePlugin from "vue-document-title-plugin";
Vue.use(VueDocumentTitlePlugin, MyRouter,
	{ defTitle: "INVRENGAR", filter: (title)=>{ return title+" - INVRENGAR"; } }
);

// export {routes};
export default MyRouter;
