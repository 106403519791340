<template lang="html">
  <div id="home-page">

    <section class="companies-section" id="s-companies">
      <div class="row no-gutters">
        <div class="col-sm-10 col-lg-4 col-company">
          <router-link class="box-company" to="/invrengar" data-aos="fade">
            <img class="fake" src="public/images/shared/empty.png">

            <div class="box-short-info">
              <div class="box-center">
                <p>
                  <img class="t-250 logo" src="public/images/invrengar-txt-white.svg">
                </p>

                <h5 class="t-250 title">Desarrollo Inmobiliario</h5>

                <div class="box-description">
                  <p class="descr">
                    Nos destacamos por nuestra dedicación a la gestión y optimización de capital en el vasto ámbito inmobiliario.
                  </p>

                  <p>
                    <a class="t-150 btn-more">CONOCER MÁS</a>
                  </p>
                </div>
              </div>
            </div>
          </router-link>
        </div>

        <div class="col-sm-10 col-lg-4 col-company">
          <router-link class="box-company" to="/brick-dream" data-aos="fade" data-aos-delay="300">
            <img class="fake" src="public/images/shared/empty.png">

            <div class="box-short-info">
              <div class="box-center">
                <p>
                  <img class="t-250 logo brickdream" src="public/images/brick-dream.png">
                </p>

                <h5 class="t-250 title">Servicios Turísticos</h5>

                <div class="box-description">
                  <p class="descr">
                    Opción privilegiada en el ámbito de las membresías vacacionales Premium.
                  </p>

                  <p>
                    <a class="t-150 btn-more">CONOCER MÁS</a>
                  </p>
                </div>
              </div>
            </div>
          </router-link>
        </div>

        <div class="col-sm-10 col-lg-4 col-company">
          <router-link class="box-company" to="/piedrangular" data-aos="fade" data-aos-delay="600">
            <img class="fake" src="public/images/shared/empty.png">

            <div class="box-short-info">
              <div class="box-center">
                <p>
                  <img class="t-250 logo" src="public/images/priedrangular.png">
                </p>

                <h5 class="t-250 title">Fondo de Inversión</h5>

                <div class="box-description">
                  <p class="descr">
                    Administración de fondos de inversión y enfoque en proyectos inmobiliarios escalables y rentables.
                  </p>

                  <p>
                    <a class="t-150 btn-more">CONOCER MÁS</a>
                  </p>
                </div>
              </div>
            </div>
          </router-link>
        </div>
      </div>
    </section>

    <!-- <section class="container med-container brands-section">
      <div class="row box-top-s1">
        <div class="col-12 col-title">
          <h2 class="title-s1">MARCAS Y ALIADOS COMERCIALES</h2>
        </div>
      </div>

      <div class="box-logos">
        <swiper class="swiper" :options="logosOptions">
          <swiper-slide v-for="(image, ihIdx) in logos" :key="'ihIdx-'+ihIdx">
            <div class="box-logo">
              <div class="img">
                <img :src="image">
              </div>
            </div>
          </swiper-slide>
        </swiper>
      </div>
    </section> -->

    <div id="s-corporativo">
      <CorporativeComponent></CorporativeComponent>
    </div>

    <section class="cv-section">
      <h5>Únete al equipo</h5>

      <p class="mt-3">
        <router-link class="_btn" to="/cv">Envíanos tu Currículum</router-link>
      </p>
    </section>

    <section class="video-section">
      <div class="container">
        <video src="public/videos/invrengar-video-home.mp4" poster="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7" controls>
          Tu navegador no admite el elemento <code>video</code>.
        </video>
      </div>
    </section>

    <!-- <section class="blog-section" data-aos="fade-up">
      <div class="container med-container">
        <div class="row box-top-s1">
          <div class="col-sm-8 col-title">
            <h2 class="title-s1"><span>COMUNICADOS OFICIALES</span></h2>
          </div>

          <div class="col-sm-4 col-btns">
            <router-link class="txt-gray" to="/comunicados">VER MÁS...</router-link>
          </div>
        </div>

        <div class="box-carusel">
          <swiper class="swiper" :options="blogOptions">
            <swiper-slide v-for="(k, khInx) in blog" :key="'khInx-'+khInx">
              <router-link class="t-250 box-article-s1" to="/comunicados/1">
                <div class="placed-backg t-250 box-image" v-bind:style="{ backgroundImage: 'url('+k.imgUrl+')' }"></div>

                <div class="box-info">
                  <h5 class="t-250 title">{{ k.title }}</h5>

                  <div class="descr">
                    <p>
                      As a manager, it's important to recognize that your leadership style
                      has a direct impact on employee retention.
                    </p>
                    <p>
                      Countless studies have shown that employees often leave their jobs
                      because of bad managers, so as a leader, it's critical to take a
                      proactive approach to your own professional development.
                    </p>
                  </div>
                </div>
              </router-link>
            </swiper-slide>

            <div class="swiper-pagination" slot="pagination"></div>
          </swiper>
        </div>
      </div>
    </section> -->

  </div>
</template>

<script>
import CorporativeComponent from './corporative/corporative-component.vue';
export default {
  components: {
    CorporativeComponent,
  },

  data() {
    return {
      logos: [
        'public/images/pages/home/logo-1.png',
        'public/images/pages/home/logo-2.png',
        'public/images/pages/home/logo-3.png',
        'public/images/pages/home/logo-4.png',
      ],

      blog: [
        { imgUrl: 'public/images/pages/blog/article-1.jpg', title: 'How to improve the leadership in your organization.' },
        { imgUrl: 'public/images/pages/blog/article-2.jpg', title: 'Top ten steps to improving corporate governance.' },
      ],

      // == Carousel options ==
      logosOptions: {
        slidesPerView: 4,
        spaceBetween: 30,
        loop: true,

        autoplay: {
          delay: 60000,
          disableOnInteraction: false
        },

        breakpoints: {
          1510: {
            slidesPerView: 4,
            spaceBetween: 80
          },
          1200: {
            slidesPerView: 4,
            spaceBetween: 50,

            autoplay: {
              delay: 60000,
              disableOnInteraction: false
            },
          },
          992: {
            slidesPerView: 3,
            spaceBetween: 55,

            autoplay: {
              delay: 5000,
              disableOnInteraction: false
            },
          },
          576: {
            slidesPerView: 2,
            spaceBetween: 60,

            autoplay: {
              delay: 5000,
              disableOnInteraction: false
            },
          },
          1: {
            slidesPerView: 1,

            autoplay: {
              delay: 5000,
              disableOnInteraction: false
            },
          },
        },
      },

      blogOptions: {
        slidesPerView: 2,
        spaceBetween: 40,

        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },

        breakpoints: {
          992: {
            slidesPerView: 2,
            spaceBetween: 40
          },
          1: {
            slidesPerView: 1,
            spaceBetween: 0
          },
        }
      }
      // == ==
    }
  }
}
</script>
