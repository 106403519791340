<template lang="html">
  <section class="about-us-section-st1">
    <div class="container med-container">
      <div class="row align-items-center">
        <div class="col-lg-6 col-image">
          <h2 class="title-s1">CORPORATIVO</h2>

          <div class="image" data-aos="fade-right">
            <img src="public/images/pages/about-us/image-1.png">
          </div>
        </div>

        <div class="col-lg-6 col-info">
          <p class="mb-3" data-aos="fade-up">
            Nacimos con la firme convicción de introducir un producto financiero que marque la diferencia en el mercado. Nuestro nombre, INVersion, RENdimiento, GARantía, refleja nuestro compromiso con la inversión, el rendimiento sostenible y la garantía de calidad.
          </p>

          <!-- <p class="mb-3">
            Proveemos garantía real ademas de rendimientos con
            tiempos de liquidación de un máximo de 15 meses e
            históricamente un mínimo de 8 meses, siempre
            considerando la rentabilidad para nuestros inversionistas y
            protegiendo los proyectos frente a cualquier eventualidad.
          </p> -->

          <div class="mt-4 box-buttons">
            <router-link class="t-150 btn-contact b1" data-aos="fade-up" data-aos-delay="300" to="/contacto">CONTACTANOS AHORA</router-link>
            <router-link class="t-150 btn-contact b2" data-aos="fade-up" data-aos-delay="600" to="">AGENDEMOS LLAMADA</router-link>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
}
</script>
