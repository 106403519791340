<template lang="html">
  <div id="company-info-page" data-aos="fade">

    <section class="main-section">
      <div class="container">
        <div class="box-logo invrengar">
          <img src="public/images/priedrangular-white.png">
        </div>

        <div class="placed-backg box-media">
          <video class="placed-backg" src="public/videos/piedra-angular.mp4" poster="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7" controls>
            Tu navegador no admite el elemento <code>video</code>.
          </video>
          <!-- <iframe src="https://www.youtube.com/embed/OPfuHuQDmc8?si=qVA9REVJBZ6EzRBh" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe> -->
        </div>

        <div class="box-content">
          <div class="box-info">
            <p class="p-lg text-center">
              Gestionamos fondos de inversión y los destinamos exclusivamente a proyectos inmobiliarios que son escalables y altamente rentables.
            </p>
          </div>

          <div class="row box-extra">
            <div class="col-12 mt-3 mb-1 box-title">
              <h5 class="text-center title-s1 t-lg">Descubre nuestro modelo de negocio</h5>
            </div>

            <div class="col-12 col-info">
              <div class="box-info">
                <div class="inside">
                  <h6 class="title">Apertura del Fideicomiso</h6>

                  <p>
                    Iniciamos la creación de un fideicomiso respaldado por garantía inmobiliaria en colaboración con alguno de nuestros socios estratégicos.
                  </p>
                </div>
              </div>
            </div>

            <div class="col-12 col-info">
              <div class="box-info">
                <div class="inside">
                  <h6 class="title">Adhesión de Inversionistas</h6>

                  <p>
                    Incorporamos a los accionistas e inversionistas en la sucursal del banco aliado con el que hemos establecido el fideicomiso.
                  </p>
                </div>
              </div>
            </div>

            <div class="col-12 col-info">
              <div class="box-info">
                <div class="inside">
                  <h6 class="title">Depósito del Capital</h6>

                  <p>
                    Todo el capital de los inversionistas se transfiere de manera directa al fideicomiso, evitando intermediarios o empresas externas.
                  </p>
                </div>
              </div>
            </div>

            <div class="col-12 col-info">
              <div class="box-info">
                <div class="inside">
                  <h6 class="title">Desarrollo Inmobiliario</h6>

                  <p>
                    Cada etapa, desde la adquisición hasta el desarrollo, construcción y escrituración, se lleva a cabo bajo la titularidad exclusiva del fideicomiso, eliminando la participación de terceros.
                  </p>
                </div>
              </div>
            </div>

            <div class="col-12 col-info">
              <div class="box-info">
                <div class="inside">
                  <h6 class="title">Venta del Proyecto</h6>

                  <p>
                    Todo proceso de venta, desde apartados y enganches hasta finiquitos de unidades, se realiza mediante depósitos directos en el fideicomiso.
                  </p>
                </div>
              </div>
            </div>

            <div class="col-12 col-info">
              <div class="box-info">
                <div class="inside">
                  <h6 class="title">Liquidación a Inversionistas</h6>

                  <p>
                    A medida que se concretan las ventas, reintegramos el capital invertido al FIDEICOMISO, junto con sus rendimientos respectivos. Una vez que todos los inversionistas han sido liquidados, el fideicomiso se extingue.
                  </p>
                </div>
              </div>
            </div>

            <div class="col-12 col-info">
              <div class="box-info">
                <div class="inside">
                  <h6 class="title">Extinción del Fideicomiso</h6>

                  <p>
                    Esta fase final y crucial garantiza la completa extinción del fideicomiso, asegurando que no haya ningún perjuicio para los inversionistas. Es un paso fundamental para mantener la integridad financiera y la confianza de nuestros colaboradores.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div class="box-extra-video">
            <img class="img-fake" src="public/images/pages/home/piedra-angular-1.jpg">
            <video class="placed-backg video-1" src="public/videos/piedra-angular-1.mp4" poster="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7" controls>
              Tu navegador no admite el elemento <code>video</code>.
            </video>
          </div>

          <div class="pt-4 pb-4 text-center box-info">
            <div class="mb-1 box-title">
              <h5 class="text-center title-s1 t-md">Invertir con PIEDRANGULAR te introduce a uno de los mercados inmobiliarios más sólidos y confiables.</h5>
            </div>

            <p class="p-md">
              Con rendimientos personalizados que pueden alcanzar un +20%, hemos canalizado exitosamente más de 65 millones de pesos en diversos proyectos inmobiliarios en 2021.
            </p>

            <p class="mt-2 p-md">
              Contamos con una red de 93 agentes en toda la República Mexicana, asegurando una presencia integral.
            </p>

            <p class="mt-2 p-md">
              Nuestro compromiso va más allá de los resultados financieros; buscamos la excelencia en cada proyecto, ofreciendo a los inversionistas una experiencia confiable y satisfactoria. Invertir con PIEDRANGULAR significa seguridad, rentabilidad y una asociación comprometida con la excelencia.
            </p>
          </div>

          <div class="row">
            <div class="col-md-6">
              <div class="box-extra-video">
                <img class="img-fake" src="public/images/pages/home/piedra-angular-2.jpg">
                <video class="placed-backg video-2" src="public/videos/piedra-angular-2.mp4" poster="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7" controls>
                  Tu navegador no admite el elemento <code>video</code>.
                </video>
              </div>
            </div>

            <div class="col-md-6 mt-3 mt-md-0">
              <div class="box-extra-video">
                <img class="img-fake" src="public/images/pages/home/piedra-angular-3.jpg">
                <video class="placed-backg video-3" src="public/videos/piedra-angular-3.mp4" poster="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7" controls>
                  Tu navegador no admite el elemento <code>video</code>.
                </video>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="cv-section">
      <h5>Descarga nuestra calculadora de proyección</h5>

      <p class="mt-3">
        <a class="_btn" download target="_blank" href="public/files/Calculadora-PiedrAngular-2024.xlsx"><i class="fas fa-calculator-alt mr-2"></i> Descargar calculadora en excel</a>
      </p>
    </section>

  </div>
</template>

<script>
export default {
}
</script>
