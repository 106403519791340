<template lang="html">
  <div id="contact-page" data-aos="fade">

    <section class="main-section">
      <div class="container oversized-container">
        <div class="box-title">
          <h1 class="title-s1">CONTÁCTANOS</h1>
        </div>

        <div class="row">
          <div class="col-lg-6 col-form">
            <b-form @submit="onSubmit">
              <div class="row">
                <div class="col-lg-6">
                  <b-form-group class="custom-f-group-s2" label="Nombre(s)">
                    <b-form-input
                      v-model="form.name"
                      type="text"
                      placeholder=""
                      required
                    ></b-form-input>
                  </b-form-group>
                </div>

                <div class="col-lg-6">
                  <b-form-group class="custom-f-group-s2" label="Apellido(s)">
                    <b-form-input
                      v-model="form.lastName"
                      type="text"
                      placeholder=""
                      required
                    ></b-form-input>
                  </b-form-group>
                </div>

                <div class="col-lg-6">
                  <b-form-group class="custom-f-group-s2" label="Correo electrónico">
                    <b-form-input
                      v-model="form.email"
                      type="email"
                      placeholder=""
                      required
                    ></b-form-input>
                  </b-form-group>
                </div>

                <div class="col-lg-6">
                  <b-form-group class="custom-f-group-s2" label="Numero telefónico">
                    <b-form-input
                      v-model="form.phone"
                      type="text"
                      placeholder=""
                      minlength="10"
                      maxlength="10"
                      required
                    ></b-form-input>
                  </b-form-group>
                </div>

                <div class="col-lg-12">
                  <b-form-group class="custom-f-group-s2" label="Mensaje">
                    <b-form-textarea
                      v-model="form.msg"
                      placeholder=""
                      rows="3"
                      max-rows="3"
                    ></b-form-textarea>
                  </b-form-group>
                </div>

                <div class="col-12 mt-4 text-center">
                  <b-button type="submit" class="px-5 btn-s1">Enviar mensaje</b-button>
                </div>
              </div>

            </b-form>
          </div>

          <div class="col-lg-6 col-xl-5 offset-xl-1 col-image">
            <div class="placed-backg img" v-bind:style="{ backgroundImage: 'url(public/images/pages/contact/img-1.jpg)' }"></div>
          </div>
        </div>
      </div>
    </section>

  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        name: null,
        lastName: null,
        email: null,
        phone: null,
        msg: null,
      }
    }
  },

  methods: {
    makeToast(variant = null, msg, title) {
      this.$bvToast.toast(msg, {
        title: title,
        variant: variant,
        solid: true,
        toaster: 'b-toaster-bottom-center',
        appendToast: true
      })
    },

    onSubmit(event) {
      event.preventDefault();

      var params = this.form;
      var apiURL = tools.url("/api/contact");

      axios.post( apiURL,params )
      .then( (response) => {
        // alert(response.data.response);
        this.makeToast('success', response.data.response, 'Mensaje enviado');
        // alert(response.data.response);

        for (let prop in this.form) {
          this.form[prop] = '';
        }
      })
      .catch( (error) => {
        // console.log(error.response.data);
        let errors = error.response.data;

        for (let prop in errors) {
          // console.log(errors[prop]);
          this.makeToast('danger', errors[prop], '¡Error!');
        }
      });
    },
  }
}
</script>
