<template lang="html">
  <div id="company-info-page" data-aos="fade">

    <section class="main-section">
      <div class="container">
        <div class="box-logo brick-dream">
          <img src="public/images/brick-dream-white.png">
        </div>

        <div class="placed-backg box-media" v-bind:style="{ backgroundImage: 'url(public/images/pages/companies/brick-dream-bg-2.jpg)' }">
          <video controls muted autoplay loop poster="public/images/pages/companies/brick-dream-bg-2.jpg">
            <source src="public/videos/brick-dream.mp4" type="video/mp4">
            Your browser does not support the video tag.
          </video>
        </div>

        <div class="box-content">
          <div class="box-info">
            <p class="p-md text-justify">
              Brick Dream se destaca como la opción privilegiada en el ámbito de las membresías vacacionales Premium, brindando a sus afiliados la oportunidad exclusiva de sumergirse en la opulencia de propiedades de lujo ubicadas en destinos selectos de México.
            </p>

            <p class="mt-3 p-md text-justify">
              Estas extraordinarias residencias no solo garantizan un estándar inigualable en calidad y seguridad, sino que también ofrecen la certeza de contar con el respaldo de nuestra sólida empresa matriz.
            </p>

            <p class="mt-3 p-md text-justify">
              La mecánica de Brick Dream se presenta de manera intuitiva y eficiente: al adquirir la membresía, los usuarios desbloquean un acceso privilegiado a nuestra aplicación dedicada. A través de esta plataforma, disfrutan de la libertad de explorar un extenso catálogo de propiedades cuidadosamente seleccionadas, con la posibilidad de realizar reservas de manera inmediata.
            </p>

            <p class="mt-3 p-md text-justify">
              La mecánica de Brick Dream se presenta de manera intuitiva y eficiente: al adquirir la membresía, los usuarios desbloquean un acceso privilegiado a nuestra aplicación dedicada. A través de esta plataforma, disfrutan de la libertad de explorar un extenso catálogo de propiedades cuidadosamente seleccionadas, con la posibilidad de realizar reservas de manera inmediata.
            </p>

            <p class="mt-3 p-md text-justify">
              En Brick Dream, no solo ofrecemos una membresía vacacional, sino que estamos comprometidos en proporcionar a nuestros afiliados una puerta de acceso a un mundo de experiencias inolvidables, respaldado por la garantía de servicio y calidad que caracteriza a nuestro grupo empresarial.
            </p>
          </div>
        </div>
      </div>
    </section>

  </div>
</template>

<script>
export default {
}
</script>
