<template lang="html">
  <div id="company-info-page">

    <section class="main-section">
      <div class="container">
        <div class="box-logo invrengar" data-aos="fade">
          <img src="public/images/invrengar-txt-white.svg">
        </div>

        <div class="placed-backg box-media" data-aos="fade" v-bind:style="{ backgroundImage: 'url(public/images/pages/companies/invrengar-bg.jpg)' }">
          <video controls muted autoplay loop poster="public/images/pages/companies/invrengar-bg-2.jpg">
            <source src="public/videos/invrengar.mp4" type="video/mp4">
            Your browser does not support the video tag.
          </video>
        </div>

        <div class="box-content">
          <div class="box-info">
            <p class="p-lg text-center">
              Como destacada empresa constructora con base en México, nos orientamos a la gestión y optimización de capital en el vasto ámbito inmobiliario.
            </p>
          </div>

          <div class="row box-extra">
            <div class="col-12 col-info">
              <div class="box-info">
                <div class="inside">
                  <h6 class="title">Gestión Integral del Capital</h6>

                  <p>
                    Nos especializamos en ofrecer servicios de administración de capital dirigidos a individuos, empresas y entidades extranjeras. Nuestra misión es guiarlos en la expansión de su patrimonio mediante inversiones estratégicas en el dinámico sector inmobiliario.
                  </p>
                </div>
              </div>
            </div>

            <div class="col-12 col-info">
              <div class="box-info">
                <div class="inside">
                  <h6 class="title">Generación de Rendimientos Sostenibles</h6>

                  <p>
                    Enfocamos nuestros esfuerzos en la creación de oportunidades de inversión que proporcionen rendimientos consistentes a lo largo del tiempo. A través de un análisis exhaustivo del mercado inmobiliario, buscamos maximizar el retorno de inversión para nuestros clientes.
                  </p>
                </div>
              </div>
            </div>

            <div class="col-12 col-info">
              <div class="box-info">
                <div class="inside">
                  <h6 class="title">Desarrollo Inmobiliario Integral</h6>

                  <p>
                    Desde la fase conceptual hasta la concreción de complejos residenciales con servicios y amenidades de alta calidad, nos especializamos en el desarrollo integral de terrenos desde cero.
                  </p>
                  <p>
                    Asumimos el compromiso de garantizar la calidad y seguridad en cada etapa de nuestros proyectos, desde la planificación hasta la ejecución y entrega final.
                  </p>
                </div>
              </div>
            </div>

            <div class="col-12 col-info">
              <div class="box-info">
                <div class="inside">
                  <h6 class="title">Transparencia y Compromiso</h6>

                  <p>
                    Operamos con transparencia y ética en todas nuestras transacciones y procesos de inversión. Nuestro compromiso con la integridad asegura a nuestros clientes la confianza necesaria en cada proyecto que emprendemos.
                  </p>
                </div>
              </div>
            </div>

            <div class="col-12 col-info">
              <div class="box-info">
                <div class="inside">
                  <h6 class="title">Innovación y Sostenibilidad</h6>

                  <p>
                    Buscamos continuamente la innovación en nuestros desarrollos, incorporando prácticas sostenibles para contribuir al bienestar del entorno y cumplir con los estándares más altos de responsabilidad social.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div class="box-info">
            <p class="p-lg text-justify">
              En resumen, nuestra empresa no solo construye propiedades, sino que también se erige como un socio estratégico comprometido con el crecimiento patrimonial de nuestros clientes y la creación de comunidades inmobiliarias sostenibles y prósperas.
            </p>

            <h6 class="mt-4 text-center title-s1 t-md">Conoce nuestros desarrollos</h6>
            <p class="mt-3 text-center">
              <a class="btn px-5 btn-s1 my-1 mx-2" target="_blank" href="public/pdfs/BROCHURE-V1.pdf">Chelem Yucatan V1</a>
              <a class="btn px-5 btn-s1 my-1 mx-2" target="_blank" href="public/pdfs/BROCHURE-V3.pdf">Chelem Yucatan V3</a>
            </p>
          </div>

          <div class="no-gutters box-gallery">
            <div class="col-6 col-md-4 col-lg-3 col-image" v-for="(image, imageIndex) in items" :key="imageIndex">
              <div class="placed-backg image" v-bind:style="{ backgroundImage: 'url('+image+')' }" @click="index = imageIndex"></div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <CoolLightBox
      :items="items"
      :index="index"
      :effect="'fade'"
      @close="index = null">
    </CoolLightBox>

  </div>
</template>

<script>
import CoolLightBox from 'vue-cool-lightbox';
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css';
export default {
  components: {
    CoolLightBox,
  },

  data() {
    return {
      items: [
        'public/images/pages/companies/image-1.jpg',
        'public/images/pages/companies/image-2.jpg',
        'public/images/pages/companies/image-3.jpg',
        'public/images/pages/companies/image-4.jpg',
        'public/images/pages/companies/image-5.jpg',
        'public/images/pages/companies/image-6.jpg',
        'public/images/pages/companies/image-7.jpg',
        'public/images/pages/companies/image-8.jpg',
        'public/images/pages/companies/image-9.jpg',
        'public/images/pages/companies/image-10.jpg',
        'public/images/pages/companies/image-11.jpg',
        'public/images/pages/companies/image-12.jpg',
        'public/images/pages/companies/image-13.jpg',
        'public/images/pages/companies/image-14.jpg',
        'public/images/pages/companies/image-15.jpg',
        'public/images/pages/companies/image-16.jpg',
        'public/images/pages/companies/image-17.jpg',
        'public/images/pages/companies/image-18.jpg',
        'public/images/pages/companies/image-19.jpg',
        'public/images/pages/companies/image-20.jpg',
        'public/images/pages/companies/image-21.jpg',
        'public/images/pages/companies/image-22.jpg',
        'public/images/pages/companies/image-23.jpg',
        'public/images/pages/companies/image-24.jpg',
        'public/images/pages/companies/image-25.jpg',
        'public/images/pages/companies/image-26.jpg',
      ],
      index: null,
    }
  }
}
</script>
