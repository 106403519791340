<template lang="html">
  <div id="blog-page" data-aos="fade">

    <section class="container med-container articles-section">
      <div class="container med-container">
        <div class="row box-top-s1">
          <div class="col-12 col-title">
            <h2 class="title-s1"><span>COMUNICADOS OFICIALES</span></h2>
          </div>
        </div>

        <div class="row box-articles">
          <div class="col-lg-6 col-article" v-for="(b, bInx) in blog" :key="'bInx-'+bInx">
            <router-link class="t-250 box-article-s1" to="/comunicados/1">
              <div class="placed-backg t-250 box-image" v-bind:style="{ backgroundImage: 'url('+b.imgUrl+')' }"></div>

              <div class="box-info">
                <h5 class="t-250 title">{{ b.title }}</h5>

                <div class="descr">
                  <p>
                    As a manager, it's important to recognize that your leadership style
                    has a direct impact on employee retention.
                  </p>
                  <p>
                    Countless studies have shown that employees often leave their jobs
                    because of bad managers, so as a leader, it's critical to take a
                    proactive approach to your own professional development.
                  </p>
                </div>
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </section>

  </div>
</template>

<script>
export default {
  data() {
    return {
      blog: [
        { imgUrl: 'public/images/pages/blog/article-1.jpg', title: 'How to improve the leadership in your organization.' },
        { imgUrl: 'public/images/pages/blog/article-2.jpg', title: 'Top ten steps to improving corporate governance.' },
        { imgUrl: 'public/images/pages/blog/article-2.jpg', title: 'Top ten steps to improving corporate governance.' },
        { imgUrl: 'public/images/pages/blog/article-1.jpg', title: 'How to improve the leadership in your organization.' },
      ],
    }
  }
}
</script>
