<template lang="html">
  <footer id="footer">
    <section class="top-section">
      <div class="container med-container">
  			<div class="row">
  				<div class="col-lg-6 col-left">
  					<p class="mb-4 mb-lg-3 text-center text-lg-left">
  						<router-link class="btn-logo" to="/">
  							<img src="public/images/isotipo.svg" alt="INVRENGAR">
  						</router-link>
  					</p>

  					<div class="row">
  						<div class="col-sm-6 col-footer">
  							<p>
  								<router-link to="/#s-companies" v-scroll-to="'#s-companies'">Empresa</router-link>
  							</p>
  							<!-- <p>
  								<router-link to="/comunicados">Comunicados</router-link>
  							</p> -->
                <p>
  								<router-link to="/corporativo">Corporativo</router-link>
  							</p>
                <p>
  								<router-link to="/">Inicio</router-link>
  							</p>
  						</div>

  						<div class="col-sm-6 col-footer">
  							<p>
  								<router-link to="">Sesión de inversionistas</router-link>
  							</p>
                <p>
  								<router-link to="">Sesión de colaboradores.</router-link>
  							</p>
  						</div>
  					</div>
  				</div>

  				<div class="col-lg-6 mt-4 mt-lg-0 col-footer col-right">
            <b-form @submit="onSubmit">
              <b-form-group class="custom-f-group-s1">
                <b-form-input
                  v-model="formNewsletter.email"
                  type="email"
                  placeholder="SUSCRÍBETE A NUESTRO BOLETÍN"
                  required
                ></b-form-input>
              </b-form-group>
              <b-button type="submit" class="btn-send">ENVIAR</b-button>
            </b-form>

            <div class="row mt-4">
              <div class="col-sm-6">
  							<p>
                  <router-link to="/terminos-y-condiciones">Términos y condiciones</router-link>
                </p>
                <!-- <p>
                  <router-link to="/garantias">Garantías</router-link>
                </p> -->
  							<p>
                  <router-link to="/aviso-de-privacidad">Aviso de privacidad</router-link>
                </p>
              </div>

              <div class="col-sm-6 mt-3 mt-sm-0 text-center text-sm-right">
                <h6 class="title">TORRE MAGNIA CORPORATE CENTER</h6>
                <span class="address">
                  Calle 15 Numero 503 Piso 7, Int 703 Fraccionamiento Altabrisa<br />
                  Mérida Yucatan CP. 97130
                </span>

                <span class="mt-2 copyright">© INVRENGAR {{ currentYear }}</span>
              </div>
            </div>
          </div>
  			</div>
    	</div>
    </section>

    <section class="container regulations-section">
      <div class="row">
        <div class="col-lg-12 col-text">
          <h6>Cumpliendo normativas y recomendaciones de:</h6>
        </div>

        <div class="col-4 col-image">
          <img src="public/images/shared/sat.png" alt="SAT">
        </div>

        <div class="col-4 col-image">
          <img src="public/images/shared/cnbv.png" alt="CNBV">
        </div>

        <div class="col-4 col-image">
          <img src="public/images/shared/bm.png" alt="BM">
        </div>
      </div>
    </section>

  </footer>
</template>

<script>
export default {
  data() {
    return {
      currentYear: 0,

      formNewsletter: {
        email: '',
      },
    }
  },

  methods: {
    onSubmit(event) {
      event.preventDefault();
    },
  },

  beforeMount() {
    this.currentYear = new Date().getFullYear();
  },
}
</script>
