<template lang="html">
  <div id="user-access-page" class="placed-backg">

    <div class="container">
      <div class="row mx-0 flex-center-xy page-size">

        <div class="form-container">
          <div class="box-color"><i><u class="fas fa-user"></u></i></div>

          <b-form class="form" @submit.prevent="register">

            <h1 class="mb-3">Registrarse</h1>

            <div class="mb-4 form-errors" v-if="formErrors.length">
              <hr />
              <ul>
                <li class="text-danger" v-for="formError in formErrors">
                  <small>{{ formError }}</small>
                </li>
              </ul>
            </div>

            <b-form-group>
              <b-form-input type="text" v-model="form.name" equired placeholder="Nombres"></b-form-input>
            </b-form-group>

            <b-form-group>
              <b-form-input type="text" v-model="form.lastname" equired placeholder="Apellidos"></b-form-input>
            </b-form-group>

            <b-form-group>
              <b-form-input type="email" v-model="form.email" required placeholder="Correo electrónico"></b-form-input>
            </b-form-group>

            <b-form-group>
              <b-form-input type="password" v-model="form.password" required placeholder="Contraseña"></b-form-input>
            </b-form-group>

            <b-form-group>
              <b-form-input type="password" v-model="form.passwordcon" required placeholder="Confirmar contraseña"></b-form-input>
            </b-form-group>

            <div class="d-inline-block mb-3">
              <b-form-checkbox v-model="form.tos" name="usertype" value="1" unchecked-value="" required>
                Acepto los términos y condiciones
              </b-form-checkbox>
            </div>

            <p class="mb-1">
              <router-link to="/login">¿Ya tiene una cuenta?</router-link>
            </p>

            <b-form-group class="mt-3">
              <b-button type="submit" class="btn-blue" variant="primary">Crear una cuenta</b-button>
            </b-form-group>

          </b-form>
        </div>

      </div>
    </div>

    <sweet-modal :icon="modal.icon" :blocking="modal.block" :hide-close-button="modal.block"  ref="modal">
      {{modal.msg}} <br/>
      <div class="col-12 text-center" style="padding-top: 20px;">
        <b-button class="btn btn-success" slot="button" v-on:click.prevent="$refs.modal.close();">Cerrar</b-button>
      </div>
    </sweet-modal>

  </div>
</template>

<script>
export default {
  data(){
    return{
      formErrors: [],

      form: {
        name: '',
        lastname: '',
        email: '',
        password: '',
        passwordcon: ''
      },

       modal:{
        msg:'',
        icon:'',
        block:false,
      },
    }
  },

  methods: {
    register(evt){
      this.formErrors = [];

      if(this.form.password.length < 6){
        this.formErrors.push("La contraseña debe tener por lo menos 6 caracteres");

        //modal
        this.modal.block = false;
        this.modal.icon = 'error';
        this.modal.msg = 'La contraseña debe tener por lo menos 6 caracteres';
        this.$refs.modal.open();
      }
      else {
        if(this.form.password !== this.form.passwordcon){
          this.formErrors.push("Los campos contraseña y confirmación de contraseña deben coincidir");
          //modal
          this.modal.block = false;
          this.modal.icon = 'error';
          this.modal.msg = 'Los campos contraseña y confirmación de contraseña deben coincidir';
          this.$refs.modal.open();
        }
        else {
          axios.post(tools.url('/front/usuario/nuevo'),this.form).then((response)=>{
            if(response.data.status == 'success')
            {
              this.$parent.user = response.data.user;
              this.$parent.token = response.data.token;
              window.axios.defaults.headers.common['Authorization'] = this.$parent.token;
              localStorage.setItem("tokenFront",this.$parent.token);
              this.$parent.logged = true;
              this.$router.push("/usuario");
            }
            else{
              this.formErrors.push(response.data.msg);
              //modal
              this.modal.block = false;
              this.modal.icon = 'error';
              this.modal.msg = response.data.msg;
              this.$refs.modal.open();
            }
          }).catch((error)=>{
            this.$refs.modal.close();
            console.log(error);
          });
        }
      }
    }
  },

  mounted()
  {
    if(this.$root.logged)
      this.$router.push("/usuario");
  }
}
</script>
