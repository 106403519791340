<template lang="html">
  <div id="article-page" data-aos="fade">

    <section class="container oversized-container main-section">
      <div class="row">
        <div class="col-lg-8 col-content">
          <div class="mb-2">
            <h1 class="title-s-1">Título del comunicado</h1>
          </div>

          <div class="box-content">
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis aliquam neque elit, vel venenatis enim sagittis vel. Nullam quis tortor consectetur, posuere purus id, venenatis nisl. Pellentesque sit amet pretium ligula. Nunc viverra at purus vel pretium. Curabitur vitae pretium elit.
            </p>
            <p>
              Nunc et orci at purus dapibus lobortis in id ante. Fusce congue nibh id mi facilisis, tempus venenatis urna tristique. Proin ante purus, semper nec felis vitae, pretium eleifend diam. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis aliquam neque elit, vel venenatis enim sagittis vel. Nullam quis tortor consectetur, posuere purus id, venenatis nisl. Pellentesque sit amet pretium ligula. Nunc viverra at purus vel pretium. Curabitur vitae pretium elit.
            </p>
            <p>
              Nunc et orci at purus dapibus lobortis in id ante. Fusce congue nibh id mi facilisis, tempus venenatis urna tristique. Proin ante purus, semper nec felis vitae, pretium eleifend diam.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis aliquam neque elit, vel venenatis enim sagittis vel. Nullam quis tortor consectetur, posuere purus id, venenatis nisl. Pellentesque sit amet pretium ligula. Nunc viverra at purus vel pretium. Curabitur vitae pretium elit.
            </p>
            <p>
              Nunc et orci at purus dapibus lobortis in id ante. Fusce congue nibh id mi facilisis, tempus venenatis urna tristique. Proin ante purus, semper nec felis vitae, pretium eleifend diam. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis aliquam neque elit, vel venenatis enim sagittis vel. Nullam quis tortor consectetur, posuere purus id, venenatis nisl. Pellentesque sit amet pretium ligula. Nunc viverra at purus vel pretium. Curabitur vitae pretium elit.
            </p>
            <p>
              Nunc et orci at purus dapibus lobortis in id ante. Fusce congue nibh id mi facilisis, tempus venenatis urna tristique. Proin ante purus, semper nec felis vitae, pretium eleifend diam.
            </p>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis aliquam neque elit, vel venenatis enim sagittis vel. Nullam quis tortor consectetur, posuere purus id, venenatis nisl. Pellentesque sit amet pretium ligula. Nunc viverra at purus vel pretium. Curabitur vitae pretium elit.
            </p>
          </div>
        </div>

        <div class="col-lg-4 pl-xl-5 mt-4 mt-lg-0 col-image">
          <div class="placed-backg image" v-bind:style="{ backgroundImage: 'url(public/images/pages/blog/photo.jpg)' }">
            <img src="public/images/shared/empty.png">
          </div>
        </div>
      </div>
    </section>

  </div>
</template>

<script>
export default {
}
</script>
