<template lang="html">
  <div id="corporative-page">

    <CorporativeComponent></CorporativeComponent>

  </div>
</template>

<script>
import CorporativeComponent from './corporative-component.vue';
export default {
  components: {
    CorporativeComponent,
  },
}
</script>
